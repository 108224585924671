import React, { useRef, useEffect, useImperativeHandle } from "react";

import { ThemeProvider } from "styled-components";
import {
  MeetingProvider,
  lightTheme,
} from "amazon-chime-sdk-component-library-react";

import { WeEvaluateMetting } from "./WeEvaluateMetting";

const WeEvaluateCamera = React.forwardRef((props, ref) => {
  const weEvaluateMettingRef = useRef(null);

  const startWeEvaluateCamera = () => {
    console.log("startWeEvaluateCamera");
    weEvaluateMettingRef.current.handleJoin();
  };
  const stopWeEvaluateCamera = () => {
    console.log("stopWeEvaluateCamera");
    weEvaluateMettingRef.current.handleEnd();
  };
  const cameraIntiated = (cameraStatus) => {
    console.log("cameraIntiated function in WeEvaluateCamera Component");
    props.cameraIntiated(cameraStatus);
  };
  const getTranscripts = (transcripts) => {
    props.getTranscripts(transcripts);
  };
  useImperativeHandle(ref, () => ({
    startWeEvaluateCamera: startWeEvaluateCamera,
    stopWeEvaluateCamera: stopWeEvaluateCamera,
  }));
  return (
    <ThemeProvider theme={lightTheme}>
      <MeetingProvider>
        <WeEvaluateMetting
          ref={weEvaluateMettingRef}
          getTranscripts={getTranscripts}
          cameraIntiated={cameraIntiated}
          questionReqObject={props.questionReqObject}
        />
      </MeetingProvider>
    </ThemeProvider>
  );
});

export default React.memo(WeEvaluateCamera);
