import * as React from 'react';

import { Component } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { connect } from "react-redux";
import {setRole } from "../redux/actions";
import l from "../assets/logo.png"
import { Button } from '@mui/material';


const categorys = [
  {
    title: 'Candidate',
    value:'candidate',
    description: [
      'You can learn the specific skills  by our tools for remote work.It helps in key areas for coorpare job',
      
    ],
    buttonText: 'Sign up for Practice',
    buttonVariant: 'outlined',
  },
  {
    title: 'Contributer',
    value:'contributer',
    description: [
      'Anyone can contribute the questions and  to earn ETH coins.You can also review the others questions.',
      
    ],
    buttonText: 'Start Contributing',
    buttonVariant: 'outlined',
  },
  {
    title: 'Hiring',
    value:'hiring',
    description: [
      'Evaluate the candiate using AI based Technology to save your time for right person to your company.',
      ],
    buttonText: 'Get started',
    buttonVariant: 'contained',
  }
];

const footers = [
  {
    title: 'Company',
    description: ['Team', 'History', 'Contact us', 'Locations'],
  },
  {
    title: 'Features',
    description: [
      'Cool stuff',
      'Random feature',
      'Team feature',
      'Developer stuff',
      'Another one',
    ],
  },
  {
    title: 'Resources',
    description: ['Resource', 'Resource name', 'Another resource', 'Final resource'],
  },
  {
    title: 'Legal',
    description: ['Privacy policy', 'Terms of use'],
  },
];
const Content = (props) => {
 
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();
 
  const gotoLoginPage = (role) => {
    console.log(props)
    props.setRole(role);
    loginWithRedirect()
  }
    return (
      <React.Fragment>
<div class="flex flex-col items-center justify-center min-h-screen bg-gray-100">
<h1 class="text-4xl md:text-5xl font-bold text-gray-800 mb-6">
        Welcome to Your AI Virtual Interview Platform!
      </h1>
  <div class="max-w-4xl px-4 py-8 grid grid-cols-1 gap-8 md:grid-cols-2">

    <div class="flex flex-col justify-center">
      
      <p class="text-lg md:text-xl text-gray-600 mb-8">
        Step into the future of recruitment with WeEvaluate! Our platform utilizes advanced AI technology to facilitate smooth and efficient virtual interviews, allowing you to showcase your skills and qualifications directly to potential employers from anywhere. Experience a seamless interview process designed to be as intuitive and engaging as an in-person discussion. Connect with top employers and make your mark in the professional world today!
      </p>
      
    </div>
    <div class="flex flex-col items-center justify-center">
      <button
        onClick={() => loginWithRedirect()}
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-full shadow-lg transition duration-300 ease-in-out focus:outline-none focus:shadow-outline"
      >
        Log In for Interview
      </button>
    </div>
   
  </div>
  <p class="text-base text-gray-700">
        Embrace the innovation of AI-driven interviews and open the door to new career opportunities. Join WeEvaluate and take the next step in your professional journey!
      </p>
</div>



     
    </React.Fragment>
    );
  }
 
  export default connect(null,{setRole})(Content);

