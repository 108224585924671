import React, { useState, useEffect } from 'react';
import { Text, Stack, TextField, PrimaryButton, Image } from '@fluentui/react';
import { Card } from '@uifabric/react-cards';
import { useParams } from "react-router-dom";
import candidateService from "../../services/candidateService";
import superagent from '../../services/weevaluateagent';
import { useAuth0 } from '@auth0/auth0-react';
const QrCodePage = ({history}) => {
  const { isAuthenticated, loginWithRedirect,user,getAccessTokenSilently } = useAuth0();
  const { tokenId } = useParams();
  const [codeValue, setCodeValue] = useState('');
  const [qrCodeImage, setQrCodeImage] = useState('');
  const [statusUpdate,setStatusUpdate]= useState(false);
  const [error,setError]=useState(null)
  useEffect(() => {
    // Simulate fetching QR code image from server
    if(!localStorage.getItem('evaluation')?.match('invited')){
      history.push(`/interview/system-check`)
    }
  }, []);

  function generateTokenJSON(jobId, candidateId) {
    const data = { jobId, candidateId };
    console.log(data)
    const encodedData = btoa(JSON.stringify(data)); // Base64 encoding
    const tokenBase = crypto.randomUUID();
    return tokenBase + '$' + encodedData;
  }
  const verifyCodeForInterviews = async()=> { 
    const token = await getAccessTokenSilently();
    superagent.setToken(token);
     const interviews =  await candidateService.verifyCodeForInterviews(user.email,codeValue)
     console.log(interviews)
     if(interviews.email.match(user.email)){
     console.log(interviews)
     setStatusUpdate(true)
     localStorage.setItem('candidate',JSON.stringify(interviews))
     const tokenId=generateTokenJSON(interviews.jobId,interviews.candidateId)
     history.push(`/interview/confirm-profile/${tokenId}`)
     }else{
      setError("Code is not Correct. Pleae try again")
     }
   }
  return (
    <Stack horizontalAlign="center" verticalAlign="center" >
      <Card tokens={{ maxWidth: '90%', maxHeight: 'auto', childrenMargin: 20 }}>
        <Card.Section>
          <Stack tokens={{ childrenGap: 20 }} horizontalAlign="center">
            <Text variant="xLarge"> Code Verification </Text>
            {qrCodeImage && <Image src={qrCodeImage} alt="QR Code" />}
            <TextField
              label="Enter Code"
              value={codeValue}
              onChange={(e, newValue) => setCodeValue(newValue || '')}
              styles={{ fieldGroup: { width: 300 } }}
            />
            <PrimaryButton text="Go" onClick={verifyCodeForInterviews} />
          </Stack>
        </Card.Section>
        <div style={{color:'red',fontSize:'20px'}}>{error}</div>
      </Card>
    </Stack>
  );
};

export default QrCodePage;
