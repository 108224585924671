import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchQuestionIds, fetchQuestionObject, saveCandidateExplanation } from "./EvaluatingAPI";
import candidateService from "../../../services/candidateService";
import WeEvaluateLogger from "../../../../../utils/WeEvaluateLogger"; 
const initialState = {
  value: 0,
  status: "idle",
  questionIdsStatus: "idle",
  questionIds: [],
  questionStatus:"idle",
  question: {},
  candidateStatus:"idle",
  candidateExplanation:{}
};

export const fetchQuestionIdsAPI = createAsyncThunk(
  "counter/fetchQuestionIds",
  async (questionReqObject) => {
    WeEvaluateLogger.log(
      "Fetching Question IDs Request Initiated",
      questionReqObject
    );
    try {
      const response =await candidateService.getQuestionIds(questionReqObject.jobpId,questionReqObject.candidateId)
   
      WeEvaluateLogger.info("Question IDs Fetch Success", response.qids);

      return response.qids;
    } catch (error) {
      WeEvaluateLogger.error("Fetching Question IDs Error", error.toString());
      return error;
    }
  }
);
// Async thunk for fetching a specific question object
export const fetchQuestionObjectAPI = createAsyncThunk(
  "evaluation/fetchQuestionObject",
  async (questionReqObject) => {
    WeEvaluateLogger.log(
      "Fetching Question Object Request Initiated",
      JSON.stringify(questionReqObject)
    );
    try {
       const response =await candidateService.getQuestionObject(questionReqObject.jobpId, questionReqObject.candidateId,questionReqObject.questionId)
      WeEvaluateLogger.info("Question Object Fetch Success", response);
      return response;
    } catch (error) {
      WeEvaluateLogger.error(
        "Fetching Question Object Error",
        error.toString()
      );
      return error;
    }
  }
);
export const saveCandidateExplanationAPI = createAsyncThunk(
  'candidate/saveExplanation',
  async (questionReqObject) => {
    WeEvaluateLogger.log('Saving Candidate Explanation Request Initiated',
    JSON.stringify(questionReqObject));
      
    try {
      const response = await candidateService.saveCandidateExplanation(
        questionReqObject.candidateId,
        questionReqObject.jobpId,
        questionReqObject.questionId,
        questionReqObject.explanation
      );
          WeEvaluateLogger.info('Candidate Explanation Saved Successfully', response);
      return response;
    } catch (error) {
      WeEvaluateLogger.error('Error Saving Candidate Explanation', error);
      throw error;
    }
  }
);

export const counterSlice = createSlice({
  name: "counter",
  initialState,

  reducers: {
    increment: (state) => {
      state.value += 1;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchQuestionIdsAPI.pending, (state) => {
        console.log("fetchQuestionIdsAPI.pending");
        state.questionIdsStatus = "loading";
      })
      .addCase(fetchQuestionIdsAPI.fulfilled, (state, action) => {
        state.questionIdsStatus = "idle";
        state.questionIds = action.payload;
      })
      .addCase(fetchQuestionObjectAPI.pending, (state) => {
        state.questionStatus = "loading";
      })
      .addCase(fetchQuestionObjectAPI.fulfilled, (state, action) => {
        state.question = action.payload;
      }) 
      .addCase(saveCandidateExplanationAPI.pending, (state) => {
        state.candidateStatus = "loading";
      })
      .addCase(saveCandidateExplanationAPI.fulfilled, (state, action) => {
        state.candidateStatus = "idle";
        console.log(action.payload)
       // state.value++
        state.candidateExplanation = action.payload+state.value;
      });
  },
});

export const { increment, decrement, incrementByAmount } = counterSlice.actions;


export const selectCount = (state) => state.counter.value;

export const selectQuestionIds = (state) => state.counter.questionIds;

export const selectQuestion = (state) => state.counter.question;

export const questionExplanation = (state) => state.counter.candidateExplanation;



export const incrementIfOdd = (amount) => (dispatch, getState) => {
  const currentValue = selectCount(getState());
  if (currentValue % 2 === 1) {
    dispatch(incrementByAmount(amount));
  }
};

export default counterSlice.reducer;
