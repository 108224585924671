import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  increment,
  selectCount,
  fetchQuestionIdsAPI,
  selectQuestionIds,
  fetchQuestionObjectAPI,
  saveCandidateExplanationAPI,
  selectQuestion,
  questionExplanation,
} from "./EvaluatingSlice"; // Importing Redux related functions from the slice

import WeEvaluateLogger from "../../../../../utils/WeEvaluateLogger"; // Importing the logger
import TimerProgressBar from "./TimerProgressBar";
import ReadQuestionAloud from "./ReadQuestionAloud";

import "./Evaluating.module.css";
import ProfileIcon from "./ProfileIcon";
import WeEvaluateCamera from "../../../components/WeEvaluateMeet/WeEvaluateCamera";
import Loading from "../../../../../components/Loading";
export const Evaluating = React.memo(
  ({ evaluationData, handleEvaluationEnd }) => {
    const readQuestionAloudRef = useRef(null);
    const audioRecorderRef = useRef(null);
    const videoRecorderRef = useRef(null);
    const [isCameraStarted, setIsCameraStarted] = useState(false);
    const [lines, setLine] = useState([]);
    const [currentLine, setCurrentLine] = useState("");
    const weEvaluateCameraRef = useRef(null);
    const count = useSelector(selectCount);
    const questionIds = useSelector(selectQuestionIds);
    const question = useSelector(selectQuestion);
    const explanation = useSelector(questionExplanation);
    const [recordedAnswers, setRecordedAnswers] = useState([]);
    const dispatch = useDispatch();
    const [initialTest, setInitialTest] = useState(false);
    const [examTime, setExamTime] = useState(parseInt(evaluationData.evaluationTime));
    const [evaluationTimer, setEvaluationTimer] = useState(examTime*60); // 30 minutes in seconds
    const [tolatEvaluationTimer, setTolatEvaluationTimer] = useState(examTime*60); // 30 minutes in seconds
    const [timer, setTimer] = useState(null); // 3 minutes in seconds
    const [questionTimer, setQuestionTimer] = useState(null);
    const [showEndButton, setShowEndButton] = useState(false);
    const [sideBar, setSideBar] = useState(true);
    const clickTimesArray = [];
    let questionTimingRecord=[];
    const questionReqObject = {
      candidateId: evaluationData.candidateId,
      jobpId: evaluationData.jobId,
    };
   let candidateExplanation=[]
    // Fetch question IDs when component mounts
    useEffect(() => {
      if (!isCameraStarted) {
       startCamera();
      }
      if (initialTest) {
        localStorage.setItem('candidateExplanation',JSON.stringify([]));
        console.log(evaluationData);
        if (evaluationData) dispatch(fetchQuestionIdsAPI(questionReqObject));

        WeEvaluateLogger.log(
          "Initiating Fetch Question IDs Request",
          questionReqObject
        );
      }
    }, [dispatch, initialTest, evaluationData, weEvaluateCameraRef]);

    // Fetch question object when question IDs are available
    useEffect(() => {
      if (questionIds.length > 0) {
        if (count === 0) {
          setTimer((examTime * 60) / (questionIds.length - 1));
          console.log('(examTime * 60) / questionIds.length - 1',examTime* 60,questionIds.length - 1,(examTime * 60) / (questionIds.length - 1))
          setQuestionTimer((examTime * 60) / (questionIds.length - 1));
          const currentTime = Date.now();
          clickTimesArray.push(currentTime);
        }
        const currentQuestionId = questionIds[count];
        questionReqObject.questionId = currentQuestionId;
        WeEvaluateLogger.log("Fetching Question Object", questionReqObject);

        dispatch(fetchQuestionObjectAPI(questionReqObject));
      }
    }, [dispatch, questionIds, count]);
    
    // Countdown timer for individual question
    useEffect(() => {
      if (initialTest && question) {
        const intervalId = setInterval(() => {
          setTimer((prevTimer) => {
            if (prevTimer <= 0) {
              clearInterval(intervalId);
              handleTimeOut(); // Handle timeout when timer reaches zero
              return 0;
            } else {
              if (prevTimer === (examTime * 60) / 10 - 1) {
                readQuestionAloudRef.current.readQuestion(question.question);
              }
              return prevTimer - 1;
            }
          });
        }, 1000); // Update every second

        return () => clearInterval(intervalId); // Cleanup function
      }
    }, [question, initialTest]);

    // Countdown timer for total evaluation time
    useEffect(() => {
      if (initialTest) {
        const totalIntervalId = setInterval(() => {
          setEvaluationTimer((prevTimer) => {
            if (prevTimer <= 0) {
              clearInterval(totalIntervalId);
              handleTotalTimeOut(); // Handle total timeout when evaluation time reaches zero
              return 0;
            } else {
              return prevTimer - 1;
            }
          });
        }, 1000); // Update every second

        return () => clearInterval(totalIntervalId); // Cleanup function
      }
    }, [dispatch, questionIds, initialTest]);

    
    const handleTimeOut = () => {
      const currentQuestionId = questionIds[count];
      questionReqObject.questionId = currentQuestionId;
      console.log("candidateExplanation",localStorage.getItem('candidateExplanation'))
     let expLines=JSON.parse(localStorage.getItem('candidateExplanation'))
      let result = expLines
      .slice(Math.max(expLines.length - 10, 0))
      .map((line, index) => line);
    
    let joinedResult = result.join('');

      questionReqObject.explanation=joinedResult+localStorage.getItem('currentLine')
      dispatch(saveCandidateExplanationAPI(questionReqObject));
      if (count < questionIds.length - 1) {
        setTimer(questionTimer); // Reset timer for the next question
        handleCountDispatch()
       

        localStorage.setItem('candidateExplanation',JSON.stringify([]));
       // candidateExplanation=[]
      }
    };

    // Handle timeout for individual questions
    function handleCountDispatch() {
      // Get the current time
      const currentTime = Date.now();
  console.log('questionTimer',questionTimer)
      // If there are previous click times
      if (clickTimesArray.length > 0) {
          // Get the time of the last click
          const lastClickTime = clickTimesArray[clickTimesArray.length - 1];
          // Calculate the time elapsed since the last click in milliseconds
          const timeElapsed = currentTime - lastClickTime;
          
          // If less than 10 seconds have passed since the last click, return
          if (timeElapsed < (questionTimer-5)*1000) {
              console.log("Please wait before clicking again.");
              return;
          }
      }
  
      // Log the current time
      console.log("Button clicked at:", new Date(currentTime).toLocaleString());
      dispatch(increment());
      // Add the current time to the clickTimesArray
      clickTimesArray.push(currentTime);
      console.log(clickTimesArray)
  }

    // Handle timeout for total evaluation time
    const handleTotalTimeOut = () => {
      // Implement your logic to handle total timeout
      stopCamera();
      setShowEndButton(true);
      setSideBar(false);
    };

    // Format timer into MM:SS format

    const startRecording = () => {
      console.log("I am in start Record");
     
    };
    const fnSetInitialTest = (event) => {
      event.preventDefault();
      localStorage.setItem('candidateExplanation',JSON.stringify([]));
      setInitialTest(true);

    };
    const cameraIntiated = (cameraStatus) => {
      console.log("cameraIntiated function in WeEvaluate Component");
      setIsCameraStarted(cameraStatus);
    };
    const getTranscripts = (transcripts) => {
      if (!transcripts.results[0].isPartial) {
        setLine((lines) => [
          ...lines,
          ` ${transcripts.results[0].alternatives[0].transcript}`,
        ]);
        if(initialTest){
          candidateExplanation.push(transcripts.results[0].alternatives[0].transcript)
      }
        setCurrentLine("");
      } else {
        setCurrentLine(`${transcripts.results[0].alternatives[0].transcript}`);
      }
    };
    const startCamera = () => {
      weEvaluateCameraRef.current.startWeEvaluateCamera();
    };
    const fnNextQuestion = () =>{
      //dispatch(increment())
    }
    const stopCamera = () => {
      console.log("I am at stop");
      weEvaluateCameraRef.current.stopWeEvaluateCamera();
    };
    return (
      <div>
        {!isCameraStarted && <Loading/>}
        <div style={{ display: isCameraStarted ? "block" : "none" }}>
          <>
            <div className="video-call-container">
              <div className="main-video">
                {!showEndButton && !initialTest && (
                  <div className="question-container">
                    <div variant="mediumPlus">Please read Above paragraph</div>{" "}
                    <br />
                    <div variant="mediumPlus">
                      {" "}
                      Hello , I'm {evaluationData.name}. You can reach me by
                      email at {evaluationData.email} or by phone at{" "}
                      {evaluationData.phone}.{" "}
                      <button style={{display:'none'}} onClick={fnSetInitialTest}>Yes</button>
                    </div>
                    <div style={{ width: "100%", fontSize: "20px" }}>
                      {lines
                        .slice(Math.max(lines.length - 10, 0))
                        .map((line, index) => (
                          <i key={index}>{line}</i>
                        ))}
                      {currentLine.length > 0 && currentLine}
                      {lines.length > 0 && (
                        <div>
                          <b>Are you able to see whta you are speaking then</b>{" "}
                          <button onClick={fnSetInitialTest}>Yes</button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {!showEndButton && question && question.question && (
                  <>
                    <TimerProgressBar
                      timer={timer}
                      totalTimer={questionTimer}
                      timerShow={false}
                      fnNextQuestion={fnNextQuestion}
                    />
                    <p className="question-container">
                      {count + 1} {question.question}
                    </p>

                   
                    <TimerProgressBar
                      timer={evaluationTimer}
                      totalTimer={tolatEvaluationTimer}
                      timerShow={true}
                    />
                       <button  style={{ display: 'none' }} onClick={fnNextQuestion}>Next Question</button>
                        <div style={{ width: "100%", fontSize: "20px" }}>
                  
                      {candidateExplanation.length > 0 && candidateExplanation}
                      </div>
                  </>
                )}
                {showEndButton && (
                  <>
                    <div className="button-container">
                      <button
                        className="interview-end-button"
                        onClick={handleEvaluationEnd}
                      >
                        End Test
                      </button>
                    </div>
                    <div className="question-container">
                      Question are over Just click End Button to End the Test
                    </div>
                  </>
                )}
              </div>

              {sideBar && (
                <div className="sidebar">
                  <div className="card">
                    {/* User card content goes here <AudioRecorder ref={audioRecorderRef} testEnd={showEndButton}/>
          <VideoRecorder ref={videoRecorderRef} testEnd={showEndButton}/>
          */}
                    <WeEvaluateCamera
                      ref={weEvaluateCameraRef}
                      getTranscripts={getTranscripts}
                      cameraIntiated={cameraIntiated}
                      questionReqObject={questionReqObject}
                    />
                  </div>
                  <div className="card">
                    {/* User card content goes here */}
                    {!showEndButton && question && question.question && (  <ReadQuestionAloud
                      question={question.question}
                      ref={readQuestionAloudRef}
                      startRecording={startRecording}
                    />)}
                    <ProfileIcon letter="A" />
                  </div>
                </div>
              )}
            </div>
          </>
        </div>
      </div>
    );
  }
);
