import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Highlight from "../components/Highlight";
import Loading from "../components/Loading";
import { getConfig } from "../config";
import agent from '../services/agent';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Assignment from "./Assignment";
import { Item } from '../components/Common'; 

  function compareRole(dbRole,role){
    console.log(dbRole)
    return dbRole === role ;
  }
export const TestComponent = () => {
    const { user } = useAuth0();
    const { apiOrigin = "http://localhost:3001", audience } = getConfig();
    const [state, setState] = React.useState({
      showResult: false,
      apiMessage: "",
      error: null,
      showAcknowledge:true,
      showAssignment: false,
    });
    const [invitation, setInvitation] = React.useState({
      to: user.email,
      code: Math.floor((Math.random() * 10000) + 1),
    });
    
     const handleChange = (event) => {
          const target = event.target;
          const name = target.name;
          console.log(event.target.name)
            //setAge(event.target.value);
            invitation[event.target.name]=event.target.value
            console.log(invitation)
           setInvitation(invitation);
          };
          
    const {
      getAccessTokenSilently,
      loginWithPopup,
      getAccessTokenWithPopup,
    } = useAuth0();
  
    const handleConsent = async () => {
      try {
        await getAccessTokenWithPopup();
        setState({
          ...state,
          error: null,
        });
      } catch (error) {
        setState({
          ...state,
          error: error.error,
        });
      }
  
      await callApi();
    };
  
    const handleLoginAgain = async () => {
      try {
        await loginWithPopup();
        setState({
          ...state,
          error: null,
        });
      } catch (error) {
        setState({
          ...state,
          error: error.error,
        });
      }
  
      await callApi();
    };
    useEffect(() => {
        console.log('Fruit', state);
      }, [state])
    const callApi = async () => {
      try {
        const token = await getAccessTokenSilently();
        agent.setToken(token);
        //console.log(agent.Invitation.verify(invitation))
       const response= await agent.Invitation.verify(invitation)
        //const responseData = await response.json();
        
        setState({
            ...state,
            showAcknowledge: response.success,
          });
        console.log(state)
       /* const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}`, },
          body: { question: 'React POST Request Example',type: 'Problem',level: 'High',formate: 'Text' }
      };
        const response = await fetch(`${apiOrigin}/api/question`, requestOptions);
  
        const responseData = await response.json();
        console.log(responseData)
        setState({
          ...state,
          showResult: true,
          apiMessage: responseData,
        });*/
      } catch (error) {
        setState({
          ...state,
          error: error.error,
        });
      }
    };
    const startTest = async () => {
      setState({
        ...state,
        showAcknowledge: false,
        showAssignment: true,
      });
    }
  return (
    <>
    {!compareRole(user["http://localhost:3000/roles"][0], 'employer') && (<Container className="mb-5">
      <center>
      {!state.showAcknowledge && !state.showAssignment && (<Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '55ch' },
      }}
      noValidate
      autoComplete="off"
    >
      
      <Stack spacing={2} direction="row">
      <TextField id="outlined-basic" label="PIN" variant="outlined" name="code" onChange={handleChange}/>
      <Button variant="contained"  onClick={() => callApi()}>Verify</Button>
     
    </Stack>
    </Box>)}
    {state.showAcknowledge && (
    <Box sx={{ flexGrow: 1,m:2 }}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Item>Test Acknowledge</Item>
      </Grid>
     
      <Grid item xs={4}>
      <FormGroup>
      <FormControlLabel control={<Checkbox  />} label="I accepted all " />
     
    </FormGroup>
      </Grid>
      <Grid item xs={8}>
         <Button variant="contained"  onClick={() => startTest()}>Start Test</Button>
      </Grid>
    </Grid>
  </Box>)}
    </center>
    {state.showAssignment && (<Assignment type="problem_solving,time_management" mode="public" limit="2,5" />)}
    </Container> )}</>
  );
};

export default withAuthenticationRequired(TestComponent, {
  onRedirecting: () => <Loading />,
});
