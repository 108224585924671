import superagentPromise from "superagent-promise";
import _superagent from "superagent";

const superagent = superagentPromise(_superagent, global.Promise);

const environment = process.env.REACT_APP_ENVIRONMENT;

  // Determine the API root based on the value of 'REACT_APP_ENVIRONMENT'
  const getApiRoot = () => {
    console.log(environment)
    try{
    if (process.env.REACT_APP_ENVIRONMENT.match('test')) {
      // If 'REACT_APP_ENVIRONMENT' is set to 'test', use the local API service
      return "http://localhost:3001/api/initial/v1";
    } else {
      // Otherwise, use the cloud service
      return "https://8eel1c30ki.execute-api.us-east-1.amazonaws.com/dev/api/initial/v1";
    }
  }catch(err){
    return "https://8eel1c30ki.execute-api.us-east-1.amazonaws.com/dev/api/initial/v1";
  }
  };

  // Get the API root
  const API_ROOT = getApiRoot();
console.log(getApiRoot())
const encode = encodeURIComponent;
const responseBody = (res) => res.body;

let token = null;
const tokenPlugin = (req) => {
  if (token) {
    console.log();
    req.set("authorization", `Bearer ${token}`);
  }
};

const requests = {
  del: (url) =>
    superagent.del(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  get: async (url) => {
    console.log(url);
    try {
      const res = await superagent
        .get(`${API_ROOT}${url}`)
        .use(tokenPlugin)
        .then(responseBody);
      return res;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  post: async (url, body) => {
    console.log(body);

    try {
      const res = await superagent
        .post(`${API_ROOT}${url}`, body)
        .use(tokenPlugin)
        .then(responseBody);
      return res;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
};

const Question = {
  save: (question) => {
    console.log(question);
    requests.post("/questions", question);
  },
  randomQuestion: (query) => {
    return requests.get(
      `/evaluationquestions?type=${query.type}&mode=${query.mode}&limit=${query.limit}`
    );
  },
};
const Template = {
  save: (template) => {
    console.log(template);
    requests.post("/template", { template });
  },
};
const Invitation = {
  send: (invitation) => {
    requests.post("/invitation", { invitation });
  },
  verify: (invitation) => {
    console.log(invitation.to);
    return requests.get(`/invitation/${invitation.to}/${invitation.code}`);
  },
  getAll:() =>{
    return requests.get(`/invitation`);
  }
};

const InvitationAPIs = {
  // get all invitations
  getAllInvitations: () => {
    return requests.get(`/invitations`);
  },

  // create an invitation
  createInvitation: (invitationData) => {
    return requests.post(`/invitation`).send(invitationData);
  },

  // get a specific invitation
  getInvitation: (id) => {
    return requests.get(`/invitation/${id}`);
  },

  // update an invitation
  updateInvitation: (id, updatedData) => {
    return requests.put(`/invitation/${id}`).send(updatedData);
  },

  // delete an invitation
  deleteInvitation: (id) => {
    return requests.delete(`/invitation/${id}`);
  },

  // verify an invitation
  verifyInvitation: (data) => {
    return requests.post(`/invitation/verify`).send(data);
  },

  // bulk invite
  bulkInvite: (data) => {
    return requests.post(`/invitation/bulk`).send(data);
  },

  // bulk delete
  bulkDelete: (data) => {
    return requests.delete(`/invitation/bulk`).send(data);
  },
};

const Profile = {
  register: (profile) => {
    return requests.post("/profile", { profile });
  },
  verify: (user) => requests.get(`/profile/${user.sub}`),
  updateStatus: (profile) =>
    requests.put(`/profiles/updateStatus`, { profile }),
};

const Auth = {
  current: () => requests.get("/user"),
  login: (email, password) =>
    requests.post("/users/login", { user: { email, password } }),
  register: (username, email, password) =>
    requests.post("/users", { user: { username, email, password } }),
  save: (user) => requests.put("/user", { user }),
};

const Tags = {
  getAll: () => requests.get("/tags"),
};

const limit = (count, p) => `limit=${count}&offset=${p ? p * count : 0}`;
const omitSlug = (article) => Object.assign({}, article, { slug: undefined });
const Articles = {
  all: (page) => requests.get(`/articles?${limit(10, page)}`),
  byAuthor: (author, page) =>
    requests.get(`/articles?author=${encode(author)}&${limit(5, page)}`),
  byTag: (tag, page) =>
    requests.get(`/articles?tag=${encode(tag)}&${limit(10, page)}`),
  del: (slug) => requests.del(`/articles/${slug}`),
  favorite: (slug) => requests.post(`/articles/${slug}/favorite`),
  favoritedBy: (author, page) =>
    requests.get(`/articles?favorited=${encode(author)}&${limit(5, page)}`),
  feed: () => requests.get("/articles/feed?limit=10&offset=0"),
  get: (slug) => requests.get(`/articles/${slug}`),
  unfavorite: (slug) => requests.del(`/articles/${slug}/favorite`),
  update: (article) =>
    requests.put(`/articles/${article.slug}`, { article: omitSlug(article) }),
  create: (article) => requests.post("/articles", { article }),
};

const Comments = {
  create: (slug, comment) =>
    requests.post(`/articles/${slug}/comments`, { comment }),
  delete: (slug, commentId) =>
    requests.del(`/articles/${slug}/comments/${commentId}`),
  forArticle: (slug) => requests.get(`/articles/${slug}/comments`),
};

export default {
  Articles,
  Auth,
  Comments,
  Profile,
  Tags,
  Question,
  Invitation,
  InvitationAPIs,
  Template,
  setToken: (_token) => {
    token = _token;
  },
};
