import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { purple } from "@mui/material/colors";

import DataGrid from "../components/DataGrid";
import Loading from "../components/Loading";
import agent from "../services/agent";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Item, ColorButton } from "../components/Common";
import DataTable from "../components/DataTable";
import { getConfig } from "../config";

export const InvitationComponent = () => {
  const { user } = useAuth0();
  const { apiOrigin = "http://localhost:3001", audience } = getConfig();

  const [invitation, setInvitation] = useState({
    firstname: "Sravathi",
    lastname: "", // Add a lastname field
    // ...other fields
  });

  const [jsonData, setJsonData] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInvitation((prevInvitation) => ({
      ...prevInvitation,
      [name]: value,
    }));
  };

  const { getAccessTokenSilently, loginWithPopup, getAccessTokenWithPopup } =
    useAuth0();

  useEffect(() => {
    getAllInvitations();
  }, []); // Add an empty dependency array to run this effect only once

  const getAllInvitations = async () => {
    try {
      const token = await getAccessTokenSilently();
      agent.setToken(token);
      const res = await agent.Invitation.getAll();
      setJsonData(res);
      setLoading(false); // Set loading to false when data is fetched
    } catch (error) {
      console.error(error);
      setLoading(false); // Set loading to false on error
    }
  };

  const callApi = async () => {
    try {
      const token = await getAccessTokenSilently();
      agent.setToken(token);
      // Rest of your API call logic here...
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container className="mb-5">
      {loading ? (
        <Loading />
      ) : (
        <>
         
          { <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Item><TextField fullWidth id="outlined-basic" label="First Name" variant="outlined" value={invitation.firstname}  name='firstname' onChange={handleChange}/></Item>
          </Grid>
          <Grid item xs={4}>
            <Item><TextField fullWidth id="outlined-basic" label="Last Name" variant="outlined" value={invitation.lastname} name='lastname' onChange={handleChange}/></Item>
          </Grid>
          <Grid item xs={4}>
            <Item><TextField fullWidth id="outlined-basic" label="Email" variant="outlined" value={invitation.to} name='to' onChange={handleChange} /></Item>
          </Grid>
          <Grid item xs={4}>
            <Item>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label" >Template</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={invitation.template}
                  label="Template"
                  name="template"
                  onChange={handleChange}
                >
                  <MenuItem value={0}>Entrelevel-1</MenuItem>
                  <MenuItem value={1}>Entrelevel-2</MenuItem>
                  <MenuItem value={2}>Entrelevel-3</MenuItem>
                </Select>
              </FormControl>
            </Item>
          </Grid>
          <Grid item xs={4}>
            <Item>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Invitation Mail Formates</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={invitation.invitationmail}
                  label="Invitation Mail Formates"
                  name="invitationmail"
                  onChange={handleChange}
                >
                  <MenuItem value={0}>Formate 1</MenuItem>
                  <MenuItem value={1}>Formate 2</MenuItem>
                  
                </Select>
              </FormControl>
            </Item>
          </Grid>
          <Grid item xs={4}>
            <Item>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Camera and Mic</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={invitation.device}
                  label="Invitation Mail Formates"
                  name="device"
                  onChange={handleChange}
                >
                  <MenuItem value={30}>None</MenuItem>
                  <MenuItem value={10}>Need Camera</MenuItem>
                  <MenuItem value={20}>Need Mic</MenuItem>
                  <MenuItem value={30}>Need Camera and Mic</MenuItem>
                </Select>
              </FormControl>
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item><TextareaAutosize
              aria-label="empty textarea"
              placeholder="Select Invitation mail formate"
              minRows={8}
              style={{ width: '100%' }}
              name="template"
              value={invitation.template}
              onChange={handleChange}
            /></Item>
          </Grid>
          <Grid item xs={12} style={{ height: '70px' }}>

            <Stack spacing={1} direction="row" style={{ float: "right" }}>
            <Button
      variant="contained"
      component="label"
    >
      Upload Resume
      <input
        type="file"
        hidden
        name="uploadFile"
      />
    </Button>
              <ColorButton variant="contained">Bulk Invite</ColorButton>
              <Button variant="contained" onClick={() => callApi()}>Send Invitation</Button>
              <Button variant="contained">Cancel</Button>


            </Stack>

          </Grid>
        </Grid>
        <Grid item xs={12} ></Grid>
      </Box> }
        </>
      )}
    </Container>
  );
};

export default withAuthenticationRequired(InvitationComponent, {
  onRedirecting: () => <Loading />,
});
