import React, { useEffect, useState } from 'react';

import { Provider } from 'react-redux';
import { store } from '../../store/index';
import { Evaluating } from './evaluation/Evaluating';
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
const EvaluationPage = ({history}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [evaluationData,setEvaluationData] =useState(null)
  const { tokenId } = useParams();
  useEffect(() => {
    let jsonObject=extractDataJSON(tokenId)
    const candidate=JSON.parse(localStorage.getItem('candidate'))
    jsonObject.name=candidate.fullName;
    jsonObject.email=candidate.email;
    jsonObject.phone=candidate.phone;
    jsonObject.evaluationTime=candidate.evaluationTime;
    
    console.log('jsonObject',jsonObject)
    setEvaluationData(jsonObject)
   
  }, [getAccessTokenSilently,tokenId]);

  const extractDataJSON = (token) => {
    const parts = token.split("$");
    if (parts.length !== 2) {
      return null; // Invalid format
    }
    const decodedData = atob(parts[1]); // Base64 decoding
    
   
    return JSON.parse(decodedData);
  };
  const handleEvaluationEnd =() =>{
    history.push(`/interview/success/${tokenId}`)
  }
   

  return (
    <Provider store={store}>
     
    { evaluationData && (<Evaluating evaluationData={evaluationData} handleEvaluationEnd={handleEvaluationEnd}/> )}
   
 </Provider>
  );
};

export default EvaluationPage;
