import React, { useState, useEffect, useRef } from "react";
import { Stack, PrimaryButton, IconButton, Icon, Text } from "@fluentui/react";
import { Card } from "@uifabric/react-cards";
import { useParams } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
import candidateService from "../../services/candidateService";
import superagent from '../../services/weevaluateagent';
import ReadQuestionAloud from "../EvaluationPage/evaluation/ReadQuestionAloud";
const SystemCheckPage = ({ history }) => {
  const { isAuthenticated, loginWithRedirect,user,getAccessTokenSilently } = useAuth0();
  const { tokenId } = useParams();
  const [statusUpdate,setStatusUpdate]= useState(false);
  const [error,setError]=useState(null);
  const readQuestionAloudRef = useRef(null);
  const [checks, setChecks] = useState({
    camera: { status: null, detail: "Checking..." },
    audio: { status: null, detail: "Checking..." },
    network: {
      status:
        navigator.connection && navigator.connection.downlink
          ? "Available"
          : "Unavailable",
      detail:
        navigator.connection && navigator.connection.downlink
          ? navigator.connection.downlink + " Mbps"
          : "No Network",
    }, // Assuming network is available if this page is rendered
    browser: { status: null, detail: "Checking..." },

  });
  const allChecksPassed = Object.values(checks).every(
    (check) => check.status === "Available"
  );

  useEffect(() => {
    // Check for camera

    console.log(tokenId);
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        setChecks((checks) => ({
          ...checks,
          camera: { status: "Available", detail: "Camera access granted." },
        }));

        stopCamera(stream);
      })
      .catch(() =>
        setChecks((checks) => ({
          ...checks,
          camera: { status: "Unavailable", detail: "Camera access denied." },
        }))
      );

    // Check for audio
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() =>
        setChecks((checks) => ({
          ...checks,
          audio: { status: "Available", detail: "Microphone access granted." },
        }))
      )
      .catch(() =>
        setChecks((checks) => ({
          ...checks,
          audio: { status: "Unavailable", detail: "Microphone access denied." },
        }))
      );

    // Simple browser check for modern features
    const isModernBrowser = "fetch" in window && "Promise" in window;
    setChecks((checks) => ({
      ...checks,
      browser: {
        status: isModernBrowser ? "Available" : "Unavailable",
        detail: isModernBrowser
          ? "Modern browser detected."
          : "Browser may not be compatible.",
      },
    }));
    
    if (allChecksPassed) {
      fetchInterviews()
    }
  }, [allChecksPassed,isAuthenticated,getAccessTokenSilently]);
   const fetchInterviews = async()=> { 
    const token = await getAccessTokenSilently();
    superagent.setToken(token);
     const interviews =  await candidateService.fetchInterviews(user.email)
     console.log(interviews)
     if(interviews.length>0 || true){
     console.log(interviews)
     setStatusUpdate(true)
    
     }else{
      const error="Hello ,"+user.name+" As of now , Interview is Not scheduled"
      readQuestionAloudRef.current.readQuestion(error);
      setError("Interview is Not scheduled")
     }
   }
  

  const stopCamera = (stream) => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
  };
  const codeVerification = () => {
    localStorage.setItem("token",tokenId)
    localStorage.setItem("evaluation",'invited')
   // loginWithRedirect();
    history.push(`/interview/qr-code/${tokenId}`)
  }

  return (
    <Stack
      horizontalAlign="center"
      verticalAlign="center"
      styles={{ width: "400px" }}
    >
      <Card
        tokens={{ maxWidth: "100%", maxHeight: "auto", childrenMargin: 20 }}
      >
        <Card.Section>
          <Stack tokens={{ childrenGap: 15 }}>
          <ReadQuestionAloud
                      question="Hi , Badri Welcome"
                      ref={readQuestionAloudRef}
                      
                    />
                  
            <Text variant="xLarge">System Check</Text>
            {Object.entries(checks).map(([key, value]) => (
              <Stack
                horizontal
                horizontalAlign="space-between"
                verticalAlign="center"
                tokens={{ childrenGap: 10 }}
                key={key}
              >
                <Icon
                  iconName={
                    value.status === "Available" ? "CheckMark" : "Cancel"
                  }
                  style={{
                    color: value.status === "Available" ? "green" : "red",
                  }}
                />
                <Text styles={{ width: "40%" }}>
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </Text>
                <Text styles={{ width: "40%" }}>{value.detail}</Text>
              </Stack>
            ))}
          </Stack>
          {allChecksPassed  && statusUpdate ? (
            <>
              <PrimaryButton
                onClick={codeVerification}
                text="Code Verification"
                style={{ marginTop: 20 }}
              />
            </>
          ):allChecksPassed && (<center>
          <div style={{color:'red',fontSize:'20px'}}>{error}</div>
          </center>)}
        </Card.Section>
      </Card>
    </Stack>
  );
};

export default SystemCheckPage;
