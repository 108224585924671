export function getConfig() {
  // Placeholder for your configJson, assuming it's defined elsewhere
  const configJson = {
    "domain": "dev-c5-mz9il.us.auth0.com",
    "clientId": "QvbSmsNbYc3HPSeNlPfDN2oo5YW0wk6b",
    "localClientId": "wxaPpb6UV5xWcBNIa3NLmAHUC1GOZGOA",
    "audience": "https://dev-c5-mz9il.us.auth0.com/api/v2/",
    "hostnames": {
      "dev.partner.weevaluate.ai": "9CpBe75Mc6X4ocod64BJUCAJzrNygdPW",
      "partner.weevaluate.ai": "QvbSmsNbYc3HPSeNlPfDN2oo5YW0wk6b",
      "beta.weevaluate.ai": "QvbSmsNbYc3HPSeNlPfDN2oo5YW0wk6b",
      "localhost": "wxaPpb6UV5xWcBNIa3NLmAHUC1GOZGOA"
    }
  };

  const auth0Config = {
    "dev.interview.weevaluate.ai": {
      domain: "dev-dyoex1hkwvugo2fu.us.auth0.com",
      clientId: "nFJW3jck5oXdxJCKyVPb3KWBfDfafj4P",
      audience: "https://dev-dyoex1hkwvugo2fu.us.auth0.com/api/v2/"
    },
    "interview.weevaluate.ai": {
      domain: "dev-dyoex1hkwvugo2fu.us.auth0.com",
      clientId: "QvbSmsNbYc3HPSeNlPfDN2oo5YW0wk6b",
      audience: "https://dev-dyoex1hkwvugo2fu.us.auth0.com/api/v2/"
    },
    "beta.weevaluate.ai": {
      domain: "dev-c5-mz9il.us.auth0.com",
      clientId: "QvbSmsNbYc3HPSeNlPfDN2oo5YW0wk6b",
      audience: "https://dev-c5-mz9il.us.auth0.com/api/v2/"
    },
    "localhost": {
      domain: "dev-c5-mz9il.us.auth0.com",
      clientId: "wxaPpb6UV5xWcBNIa3NLmAHUC1GOZGOA",
      audience: "https://dev-c5-mz9il.us.auth0.com/api/v2/"
    }
  };
  const hostname = window.location.hostname;
  // Function to get the appropriate client ID based on the hostname
  

  return auth0Config[hostname];
}
