// constants.js

export const sectors = [
    "Information Technology",
    "Healthcare",
    "Finance",
    "Education",
    "Manufacturing",
    "Retail",
    "Construction",
    "Transportation",
    "Energy",
    "Agriculture",
    "Hospitality",
    "Real Estate",
    "Telecommunications",
    "Media and Entertainment",
    "Pharmaceuticals",
    "Consulting",
    "Government/Public Sector",
    "Non-Profit",
    "Legal",
    "Aerospace and Defense"
];

export const subjects = [
    "Computer Science",
    "Mathematics",
    "Economics",
    "Business Administration",
    "Engineering (Mechanical, Electrical, Civil, etc.)",
    "Biology",
    "Chemistry",
    "Physics",
    "Environmental Science",
    "Medicine",
    "Nursing",
    "Psychology",
    "Sociology",
    "Political Science",
    "History",
    "Literature",
    "Art and Design",
    "Music",
    "Philosophy",
    "Languages (English, Spanish, French, etc.)"
];
export const experiences = [
    "0-3",
    "3-5",
    "5-8",
    "8+"
]


export const config = {
    "userPoolRegion": "us-east-1",
    "identityPoolId": "us-east-1:9766e2f2-4186-403f-b497-4af96baf0edd",
    "userPoolId": "us-east-1_DVBVy6fu8",
    "userPoolClientId": "1hpif9tnsk0hrhpn7e4svmv35c",
    "apiUrl": "https://xlnitpz6f0.execute-api.us-east-1.amazonaws.com/prod/",
    "concatBucket": "amazonchimesdkwithtranscr-mediapipelineconcatbucke-8vuty4bgrlge"
  };
  export const AmplifyConfig = {
    Auth: {
      region: config.userPoolRegion,
      identityPoolId: config.identityPoolId,
      userPoolId: config.userPoolId,
      userPoolWebClientId: config.userPoolClientId,
      mandatorySignIn: true,
      cookieStorage: {
        domain: `${window.location.hostname}`,
        path: '/',
        expires: 365,
        secure: true,
      },
    },
    Analytics: {
      disabled: true,
    },
    API: {
      endpoints: [
        {
          name: 'meetingApi',
          endpoint: config.apiUrl,
        },
      ],
    },
    Storage: {
      AWSS3: {
        bucket: config.concatBucket,
        region: config.userPoolRegion,
      },
    },
  };
