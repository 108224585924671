import React, { useEffect, useImperativeHandle, useState,useRef } from 'react';
import candidateService from '../../../services/candidateService';

const ReadQuestionAloud = React.forwardRef((props, ref) => {
  const [text, setText] = useState(props.question);
  const [audio, setAudio] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userClicked,setUserClicked] =useState(false)
  useEffect(() => {
    if(props.question){
 
     console.log('ReadQuestionAloud props.question',props.question)
      //readQuestion(props.question)
     
    }
}, [props.question]);



  
  const handleAudioEnded = () => {
    console.log('Audio playback completed');
    setLoading(false);
    // Add any further actions you want to perform after audio playback completes
  }


  const readQuestion = async (question) => {
    console.log('I came here',question)
    if(question && !loading){
    setLoading(true);
    const audioUrl = await candidateService.readQuestion(question)
console.log(audioUrl.url)
   const audio = new Audio(audioUrl.url);
    audio.play();
    audio.addEventListener('ended', handleAudioEnded);
  
    setAudio(audioUrl);
    setLoading(false);
    }else{
      console.log(question)
    }
  };
  useImperativeHandle(ref, () => ({
    readQuestion: readQuestion,
  

  }));
 
  return (
    <div >

      <button onClick={() =>{readQuestion();setUserClicked(true)}} id='myButton' disabled={loading} style={{display:'none'}}>
        {loading ? 'Loading...' : '..'}
       
      </button>
     
    {/*  {audio && <audio   id="myVideo" style={{'display':'none'}} controls src={audio} />}*/}
    </div>
  );
}
)
export default ReadQuestionAloud;
