import React, { useState, useRef } from 'react';
import { Text, IconButton, Stack, PrimaryButton } from '@fluentui/react';
import { Card } from '@uifabric/react-cards';
import { useParams } from "react-router-dom";
const VoiceVerificationPage = ({ history,fnSetInitialTest,name,email,phone }) => {
  const { tokenId } = useParams();
  const [recording, setRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState('');
  const mediaRecorderRef = useRef(null);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;
      mediaRecorder.start();
      setRecording(true);

      const audioChunks = [];
      mediaRecorder.ondataavailable = event => {
        audioChunks.push(event.data);
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunks);
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioUrl(audioUrl);
      };
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    setRecording(false);
  };

  const playRecording = () => {
    const audio = new Audio(audioUrl);
    audio.play();
  };
  const handleVoiceVerificationComplete = () => {
    // Logic after voice verification
   // history.push(`/interview/evaluation/${tokenId}`);
   console.log(fnSetInitialTest)
   fnSetInitialTest()
  };
  return (
    <Stack horizontalAlign="center" verticalAlign="center" tokens={{ childrenGap: 20, padding: '2rem 0' }}>
      <Card tokens={{ maxWidth: '500px', padding: '2rem' }} styles={{ root: { width: '100%' } }}>
        <Stack tokens={{ childrenGap: 20 }} horizontalAlign="space-between" horizontal wrap>
          <Card.Section>
         
            <Text variant="mediumPlus"> Hello , I'm {name}. You can reach me by email at {email} or by phone at {phone}. </Text>
            <br></br>
            <Text variant="mediumPlus">Click on Mic and Please read Above  paragraph</Text>
           <center> <IconButton iconProps={{ iconName: recording ? 'MicOff' : 'Microphone' }} title={recording ? 'Stop Recording' : 'Start Recording'} onClick={recording ? stopRecording : startRecording} />
           </center>
          </Card.Section>
          <Card.Section>
          {audioUrl && <> <Text variant="mediumPlus">Click the play button to make sure your audio is recording correctly."</Text>
          <IconButton iconProps={{ iconName: 'Play' }} title="Play Recording" onClick={playRecording} /></>}
          </Card.Section>
        </Stack>
        <PrimaryButton text="Confirm" onClick={handleVoiceVerificationComplete} disabled={!audioUrl} />
      </Card>
    </Stack>
  );
};

export default VoiceVerificationPage;