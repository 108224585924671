import React, { useEffect } from 'react';
import {Route } from 'react-router-dom';


import SystemCheckPage from './pages/SystemCheckPage/SystemCheckPage';
import QrCodePage from './pages/QrCodePage/QrCodePage';
import ConfirmProfilePage from './pages/ConfirmProfilePage/ConfirmProfilePage';
import VoiceVerificationPage from './pages/VoiceVerificationPage/VoiceVerificationPage';
import EvaluationPage from './pages/EvaluationPage/EvaluationPage';
import SuccessPage from './pages/SuccessPage/SuccessPage';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
 // This initializes the icons used by Fluent UI components
 import { Provider } from 'react-redux';
 import { store } from './store';
function EvaluationPanelFeature() {
  useEffect(() => {
    initializeIcons()
 
  }, []);

  return (
  <React.Fragment>
    <Provider store={store}>
        <Route path="/interview/system-check" component={SystemCheckPage} />
        <Route path="/interview/qr-code/:tokenId" component={QrCodePage} />
        <Route path="/interview/confirm-profile/:tokenId" component={ConfirmProfilePage} />
        <Route path="/interview/voice-verification/:tokenId" component={VoiceVerificationPage} />
        <Route path="/interview/evaluation/:tokenId" component={EvaluationPage} />
        <Route path="/interview/success/:tokenId" component={SuccessPage} />
        </Provider> 
        </React.Fragment>
  );
}

export default EvaluationPanelFeature;
