import React, { useEffect, useState } from 'react';

function TimerProgressBar({ timer,totalTimer, timerShow}) {



  const progressBarStyle=(timer) =>{return  {
    width: `${(timer / totalTimer) * 100}%`,
    height: '25px',
    backgroundColor: ((timer / totalTimer) * 100)<3?'red':'green',
    transition: 'width 1s ease-in-out'
  }};

  const formatTimer = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };
  return (
    <> { timerShow && <p>Time Remaining: {formatTimer(timer)}</p> }
    <div style={{ width: '100%',  backgroundColor: ((timer / totalTimer) * 100)>=8 ?'#f0f0f0':'orange' }}>
      <div style={progressBarStyle(timer)}></div>
    </div>
  
    </>
  );
}

export default TimerProgressBar