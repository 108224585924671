import superagentPromise from "superagent-promise";
import _superagent from "superagent";
import apiUrls from "../../../utils/apiUrls";

const superagent = superagentPromise(_superagent, global.Promise);
const environment = process.env.REACT_APP_ENVIRONMENT;
//const API_ROOT = "http://localhost:3001/api";
const hostname = window.location.hostname;
const API_ROOT = apiUrls[hostname] || "https://api.interview.weevaluate.ai/";

const encode = encodeURIComponent;
const responseBody = (res) => res.body;
const responseBodyFile = (res) => res.text;


let token = null;
const tokenPlugin = (req) => {
  if (token) {
    console.log(token)
    req.set("authorization", `Bearer ${token}`);
  }
};

const requestsA = {
    del: (url) =>
      superagent.del(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
    get: async (url) => {
      console.log(url);
      try {
        const res = await superagent
          .get(`${API_ROOT}${url}`)
          .use(tokenPlugin)
          .then(responseBody);
        return res;
      } catch (err) {
        console.log(err);
        return err;
      }
    },
    put: (url, body) =>
      superagent
        .put(`${API_ROOT}${url}`, body)
        .use(tokenPlugin)
        .then(responseBody),
    post: async (url, body) => {
      console.log(body);
  
      try {
        const res = await superagent
          .post(`${API_ROOT}${url}`, body)
          .use(tokenPlugin)
          .then(responseBody);
         
        return res;
      } catch (err) {
        console.log(err);
        return err;
      }
    },
    postFile: async (url, body) => {
      console.log(body);
  const question=body
      try {
      const response = await fetch(`${API_ROOT}${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "authorization": `Bearer ${token}`
      },
      body: JSON.stringify({ question }),
    })

       /* const res = await superagent
          .post(`${API_ROOT}${url}`, body)
          .use(tokenPlugin)
          .then(responseBodyFile);
          console.log("superAgent",res)*/
        return response;
      } catch (err) {
        console.log(err);
        return err;
      }
    },
  };
  const reqABC = () => {
    return requestsA
  };
export default {
  superagent,
  requestsA,
  API_ROOT,
  encode,
  responseBody,
  tokenPlugin,
  setToken: (_token) => {
    token = _token;
  },
  reqA: () => {
    return requestsA
  },
};
