import React, {useEffect } from 'react';


const EvaluationApp = ({history}) => {



  useEffect(() => {
    // Simulate fetching QR code image from server
    history.push(`/interview/system-check`)
  }, []);

 

  return (
    <></>
  );
};

export default EvaluationApp;
