// CustomLogger.js

class WeEvaluateLogger {
    static log(message,data) {
      // You can customize this method to log messages in any way you want
      console.log(`[WeEvaluateLogger] ${message} ${data}`);
    }
  
    static info(message,data) {

      console.info(`[WeEvaluateLogger] INFO: ${message}`,data);
    }
  
    static warn(message,data) {
      console.warn(`[WeEvaluateLogger] WARNING: ${message} ${data}`);
    }
  
    static error(message,data) {
      console.error(`[WeEvaluateLogger] ERROR: ${message} ${data}`);
    }
  }
  
  export default WeEvaluateLogger;
  