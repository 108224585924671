import React, { useState, useEffect } from 'react';
import './Evaluating.module.css'
function ProfileIcon({ letter }) {
  const [isAnimating, setIsAnimating] = useState(true);

  useEffect(() => {
    // Add any logic for starting/stopping animation if needed
  }, [isAnimating]);

  const handleClick = () => {
    // You might want to change the animation state here
    // setIsAnimating(!isAnimating); 
  };

  return (
    <div className="container" onClick={handleClick}>
      <div className={`profile-icon ${isAnimating ? 'animating' : 'shrinking'}`}> 
        <div className="profile-icon-inner">{letter}</div>
      </div>
    </div>
  );
}

export default ProfileIcon;
