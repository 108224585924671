import React, { useState } from "react";
import {Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import Home from "../views/Home";
import Profile from "../views/Profile";
import ExternalApi from "../views/ExternalApi";
import Questions from "../views/Questions";
import Template from "../views/Template"
import { useAuth0 } from "@auth0/auth0-react";
import { getRole } from "../redux/selectors";

// styles
import "../App.css";

// fontawesome
import initFontAwesome from "../utils/initFontAwesome";
import Dashboard from "../views/Dashboard";
import Invitation from "../views/Invitation";
import Test from "../views/Test";
import commonJSutils from "../utils/common";
import QuestionsManager from "../views/QuestionsManager";

import RedirectComponent from "./RedirectComponent";

import EvaluationApp from "../features/evaluationpanel/pages";

initFontAwesome();
function compareRole(dbRole,role){
  return dbRole === role ;
}
const AppRouter = () => {
  const {  user,isAuthenticated} = useAuth0();
  const [role,setRole]=useState(null)
  React.useEffect(() => {
    const startType = localStorage.getItem('startType');
    if (!startType) return; 
  
    switch (startType) {
      case 'attend':
        setRole('candidate');
        break;
      case 'schedule':
        setRole('employer');
        break;
      default:
        // Handle if no matching startType is found
    }
  }, []); 
   return (
   
        <React.Fragment>
        
            {!isAuthenticated && (<Route path="/" exact component={Home} />)}

             {isAuthenticated  && (<Route path="/" component={EvaluationApp} />)}
             
            
      
         
          
          </React.Fragment>
       
  );
};
const mapStateToProps = state => {
 
  const role=getRole(state)
  return { role };

};
export default connect(mapStateToProps)(AppRouter);
