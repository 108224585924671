import React, { useState } from 'react';
import axios from 'axios';
import { experiences, sectors, subjects } from '../utils/constants';
import { TextField, MenuItem, Button, FormControl, InputLabel, Select, Grid } from '@mui/material';


const QuestionForm = ({ questionData, onSave, sectors, experiences, subjects }) => {
    const [questionText, setQuestionText] = useState(questionData?.text || '');
    const [sector, setSector] = useState(questionData?.sector || '');
    const [experience, setExperience] = useState(questionData?.experience || '');
    const [subject, setSubject] = useState(questionData?.subject || '');

    const handleSubmit = async (event) => {
        event.preventDefault();
        const question = { text: questionText, sector, experience, subject };
        try {
            if (questionData) {
                await axios.put(`/api/questions/${questionData.id}`, question);
            } else {
                await axios.post('/api/questions', question);
            }
            onSave();
        } catch (error) {
            console.error('Error saving question', error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
            {/* First Column */}
            <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="normal">
                    <InputLabel id="sector-label">Select Sector</InputLabel>
                    <Select
                        labelId="sector-label"
                        value={sector}
                        label="Select Sector"
                        onChange={(e) => setSector(e.target.value)}
                        required
                    >
                        {sectors?.map((s) => (
                            <MenuItem key={s} value={s}>{s}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <InputLabel id="experience-label">Select Experience Level</InputLabel>
                    <Select
                        labelId="experience-label"
                        value={experience}
                        label="Select Experience Level"
                        onChange={(e) => setExperience(e.target.value)}
                        required
                    >
                        {experiences?.map((e) => (
                            <MenuItem key={e} value={e}>{e}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <InputLabel id="subject-label">Select Subject</InputLabel>
                    <Select
                        labelId="subject-label"
                        value={subject}
                        label="Select Subject"
                        onChange={(e) => setSubject(e.target.value)}
                        required
                    >
                        {subjects?.map((s) => (
                            <MenuItem key={s} value={s}>{s}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            {/* Second Column */}
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    margin="normal"
                    label="Question"
                    value={questionText}
                    onChange={(e) => setQuestionText(e.target.value)}
                    placeholder="Enter question here"
                    multiline
                    required
                />

                <TextField
                    fullWidth
                    margin="normal"
                    label="Answer"
                    value={questionText} // Remember to update this to a separate state for the answer
                    onChange={(e) => setQuestionText(e.target.value)} // And a separate handler for the answer
                    placeholder="Enter answer here"
                    multiline
                    required
                />
            </Grid>
        </Grid>

        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
            Save Question
        </Button>
    </form>
    );
};
QuestionForm.defaultProps = {
    sectors: sectors,
    experiences: experiences,
    subjects: subjects,
};
export default QuestionForm;
