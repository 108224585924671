import React from 'react';
import { TextField, PrimaryButton,Stack } from '@fluentui/react';
import { Card } from '@uifabric/react-cards';
import { useParams } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
const SuccessPage = () => {
  const { tokenId } = useParams();
  const { logout } = useAuth0();
  const handleSubmitFeedback = () => {
    // Logic for submitting feedback
    alert('Feedback Submited Successfully')
    logout({ returnTo: window.location.origin }); 
  };

  return (
    <Stack horizontalAlign="center" verticalAlign="center" styles={ {  margin:20 , width: 500  }  }>
    <Card tokens={{ maxWidth: '80%', maxHeight: 'auto', height: 'calc(100% - 50px)' , childrenMargin: 10,padding:20 }}>
    
      <h1>Interview Completed Successfully</h1>
      <TextField label="Positives" multiline rows={3} />
      <TextField label="Negatives" multiline rows={3} />
      <TextField label="Feedback" multiline rows={3} />
      <PrimaryButton onClick={handleSubmitFeedback} text="Submit Feedback" />
    
    </Card>
    </Stack>
  );
};

export default SuccessPage;
