import React, { useState, useRef, useEffect } from 'react';
import { Stack, TextField, PrimaryButton, Image, IconButton } from '@fluentui/react';
import { Card } from '@uifabric/react-cards';
import { useParams } from "react-router-dom";
const ConfirmProfilePage = ({ history }) => {
  const { tokenId } = useParams();
  const [candidate,setCandidate] = useState("")
  const [acknowledge, setAcknowledge] = useState(null);

  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const handleSubmit = () => {

    history.push(`/interview/evaluation/${tokenId}`);
  };
 

  const selectAcknowledge = () => {
if(!acknowledge){
    setAcknowledge(true);
}else{
  setAcknowledge(false);
}
    
  };

  useEffect(() => {
    setCandidate(JSON.parse(localStorage.getItem('candidate')))
    console.log(candidate)
   

  }, []);
  return (
   
    <Stack horizontalAlign="center" verticalAlign="center" styles={{ root: { margin: '0 auto', width: '100%'} }}>
    <Card tokens={{ maxWidth: '90%', maxHeight: 'auto', childrenMargin: 20 }}>
      <Card.Section>
        <Stack horizontal tokens={{ childrenGap: 20 }} horizontalAlign="space-between">
          {/* Column for the profile info and selfie confirmation */}
          <Stack.Item>
            <Stack tokens={{ childrenGap: 15 }}>
             
              <sapn><b style={{paddingRight:'10px'}}>Name</b>{candidate.fullName}</sapn>
              <sapn><b style={{paddingRight:'10px'}}>Email</b>{candidate.email}</sapn>
              <sapn><b style={{paddingRight:'10px'}}>Phone</b>{candidate.phone}</sapn>
              <sapn><input type='checkbox' value={acknowledge} onChange={selectAcknowledge} style={{marginRight:'10px'}}></input>Could you please acknowledge above details</sapn>
              
              {acknowledge && <PrimaryButton text="Confirm" onClick={handleSubmit} />}
            </Stack>
          </Stack.Item>
          
          
        </Stack>
      </Card.Section>
    </Card>
  </Stack>
     
  );
};

export default ConfirmProfilePage;
